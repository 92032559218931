import { FrameworkPage, FrameworkPageProps } from 'src/design-system'
import * as React from 'react'
import { openModal } from '../utils/open-modal'

export const PublicFramework: React.VFC<FrameworkPageProps> = (props) => {
  return (
    <FrameworkPage
      className="h-full"
      categories={props.categories}
      disciplines={props.disciplines}
      eventHandlers={{
        positionClickHandler: ({ position }) => {
          openModal(
            `/view_position_modal?position_id=${position.id}&public=true`
          )
        },
        requirementClickHandler: (props) => {
          const { requirement } = props

          if (requirement) {
            openModal(
              `/view_requirement_modal?modal_id=req_${requirement.id}&public=true&requirement_id=${requirement.id}`
            )
          }
        },
        skillClickHandler: (skill) => {
          openModal(
            `/view_skill_modal?framework_id=${props.frameworkId}&open_as_page=true&public=true&skill_id=${skill.id}&show_make_a_copy=false`
          )
        },
      }}
      requirements={props.requirements}
      uncategorisedSkills={props.uncategorisedSkills}
      permissions={props.permissions}
    />
  )
}
