import { forRails } from 'components/for-rails'
import { VFC } from 'react'
import { PublicFramework } from '../src/components/public-framework'
import { PublicPositions } from '../src/components/public-positions'
import { PublicSkillsGrid } from '../src/components/public-skills-grid'
import { registerComponents } from '../src/utils/react/registry'

registerComponents({
  PublicSkillsGrid: forRails(PublicSkillsGrid),
  PublicPositions: forRails(PublicPositions),
  PublicFramework: forRails(PublicFramework),
})
