import React from 'react'
import { SkillsGrid, SkillsGridProps } from 'src/design-system'
import { openModal } from '../utils/open-modal'

export type PublicSkillsGridProps = Pick<
  SkillsGridProps,
  'categories' | 'skills' | 'skillVariants'
> & { frameworkId: number | string }

export const PublicSkillsGrid = (props: PublicSkillsGridProps) => {
  return (
    <div className="bg-background">
      <SkillsGrid
        categories={props.categories}
        eventHandlers={{
          onClickSkill: (skill) => {
            openModal(
              `/view_skill_modal?open_as_page=true&show_make_a_copy=false&public=true&skill_id=${skill?.id}&framework_id=${props.frameworkId}`
            )
          },
        }}
        permissions={{
          allowEdit: false,
        }}
        skills={props.skills || []}
        skillVariants={props.skillVariants || []}
      />
    </div>
  )
}
