import React from 'react'
import { openModal } from '../utils/open-modal'
import {
  PositionsPage,
  PositionsPageProps,
} from '../pages/positions-page/positions-page'

export type PublicPositionsProps = PositionsPageProps

export const PublicPositions: React.VFC<PublicPositionsProps> = (props) => {
  return (
    <PositionsPage
      disciplines={props.disciplines}
      eventHandlers={{
        onClickPosition: (position) => {
          openModal(
            `/view_position_modal?public=true&position_id=${position?.id}`
          )
        },
      }}
      permissions={props.permissions}
    />
  )
}
